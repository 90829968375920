import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  Grid,
  AppBar,
  Tabs,
  Tab,
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  CircularProgress,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/styles";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { useAlert } from "../components/Alert";

const GET_CATEGORIES = gql`
  query categories {
    categories {
      id
      name
    }
  }
`;

const GET_PRODUCTS = gql`
  query products(
    $categoryId: Int
    $latest: Boolean
    $pageSize: Int
    $page: Int
  ) {
    products(
      categoryId: $categoryId
      latest: $latest
      pageSize: $pageSize
      page: $page
    ) {
      pageCount
      contents {
        id
        images {
          location
        }
        name
        price
        category {
          id
          name
        }
      }
    }
  }
`;

// ANCHOR export default productListPage
export default function ProductListPage() {
  return (
    <div>
      <HomeTabs />
    </div>
  );
}

function HomeTabs() {
  const Alert = useAlert();
  const theme = useTheme();
  let { typeName } = useParams();
  const useStyles = makeStyles(() => ({
    tabBox: {
      minHeight: "100vh",
    },
    containerLoading: {
      height: "50vh",
      alignContent: "center",
    },
  }));
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [page, setPage] = useState(1);
  const history = useHistory();
  const renderFirstRef = useRef(true);

  const { data, loading } = useQuery(GET_CATEGORIES, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ categories }) {
      if (categories && categories[0]) {
        let has = null;
        let hasIndex = null;
        if (Boolean(typeName)) {
          has = categories.find((item) => item.name === typeName);
          hasIndex = categories.findIndex((item) => item.name === typeName);
        }
        if (Boolean(has) && Boolean(hasIndex) && hasIndex !== -1) {
          setValue(() => {
            getProducts({
              variables: {
                categoryId: has.id,
                latest: true,
                pageSize: 10,
                page: page,
              },
            });
            return hasIndex;
          });
        } else {
          getProducts({
            variables: {
              categoryId: categories[0].id,
              latest: true,
              pageSize: 10,
              page: page,
            },
          });
        }
      }
    },
    onError(error) {
      return Alert.notify(error.message.replace("GraphQL error: ", ""));
    },
  });

  const [getProducts, { data: productsData, loading: productsLoading }] =
    useLazyQuery(GET_PRODUCTS, {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted({ products }) {
        if (products && renderFirstRef.current) {
          renderFirstRef.current = false;
        }
      },
      onError(error) {
        return Alert.notify(error.message.replace("GraphQL error: ", ""));
      },
    });

  useEffect(() => {
    if (!renderFirstRef.current) {
      getProducts({
        variables: {
          categoryId: Number(value) + 1,
          latest: true,
          pageSize: 10,
          page: page,
        },
      });
    }
  }, [value, page]);

  return (
    <div className={classes.tabBox}>
      <AppBar position="static" color="inherit">
        {loading ? (
          <Grid container justify="center">
            <CircularProgress color="secondary" />
          </Grid>
        ) : (
          <Tabs
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            variant="fullWidth"
            TabIndicatorProps={{
              style: { background: theme.palette.primary.main, height: "5px" },
            }}
          >
            {data?.categories.map((item, index) => (
              <Tab
                key={index}
                label={item.name}
                style={{ width: "100%" }}
                onClick={() => history.push("/product-list/" + item.name)}
              />
            ))}
          </Tabs>
        )}
      </AppBar>
      {productsLoading ? (
        <Grid container justify="center" className={classes.containerLoading}>
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <>
          {Boolean(productsData) && productsData.products.contents[0] ? (
            <TabLists
              data={productsData.products.contents}
              page={{ page, pageCount: productsData.products.pageCount }}
              changepPage={(n) => setPage(n)}
            />
          ) : (
            <Grid
              container
              justify="center"
              className={classes.containerLoading}
            >
              <Typography
                variant="h6"
                style={{
                  color: theme.palette.primary.main,
                }}
              >
                No products
              </Typography>
            </Grid>
          )}
        </>
      )}
    </div>
  );
}

// 各tab list
function TabLists({ data, page, changepPage = () => {} }) {
  const theme = useTheme();
  const isPhone = useMediaQuery("(max-width:600px)");
  const history = useHistory();
  const useStyles = makeStyles({
    productListCard: {
      maxWidth: "100%",
      width: 280,
    },
    media: {
      height: 300,
      width: "100%",
      objectFit: "fill",
    },
    set: {
      position: "absolute",
      background: theme.palette.primary.main,
      right: 0,
      width: "74px",
      height: "32px",
      textAlign: "center",
      lineHeight: "32px",
      borderRadius: "4px",
    },
    itemName: {
      display: "-webkit-box",
      lineClamp: "2",
      boxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontWeight: 700,
    },
  });
  const classes = useStyles();

  return (
    <Box mb={12} mt={4}>
      <Container maxWidth="xl">
        {/* lists */}
        <Box>
          <Grid container spacing={4}>
            {/* list.map */}
            {data.map((item, index) => {
              return (
                <Grid
                  item
                  container
                  justifyContent="center"
                  xs={isPhone ? 12 : 6}
                  sm={6}
                  md={4}
                  lg={3}
                >
                  <Card className={classes.productListCard}>
                    <CardActionArea
                      onClick={() => history.push(`/product/${item.id}`)}
                    >
                      <Box display="flex" justifyConter="center">
                        <img
                          src={item.images && item.images[0]?.location}
                          className={classes.media}
                        />
                      </Box>
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="subtitle1"
                          component="h2"
                          className={classes.itemName}
                        >
                          {item.name}
                        </Typography>
                      </CardContent>
                      <CardActions
                        style={{
                          flex: 1,
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography
                          variant="h6"
                          color="primary"
                          style={{ fontWeight: 700 }}
                        >
                          $ {(item.price * 29.5).toFixed()} NTD
                        </Typography>
                        <Typography
                          variant={"body1"}
                          style={{ fontWeight: 700 }}
                        >
                          approximately
                        </Typography>
                        <Typography
                          variant={"body1"}
                          style={{ fontWeight: 700 }}
                        >
                          {item.price} usd
                        </Typography>
                      </CardActions>
                    </CardActionArea>
                  </Card>
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Pagination
                  count={page.pageCount ? page.pageCount : 1}
                  boundaryCount={2}
                  shape="rounded"
                  color="primary"
                  page={page.page}
                  onChange={(e, n) => changepPage(n)}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
