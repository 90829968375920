import React from "react";
import { useHistory } from "react-router-dom";
import { useForm, Controller, useWatch } from "react-hook-form";
import {
  makeStyles,
  Typography,
  Box,
  Container,
  Grid,
  Button,
  useMediaQuery,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";

import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { useAlert } from "../components/Alert";
import LoadingModal from "../components/LoadingModal";
import { useLocalStorage } from "../components/LocalStorageContext";

import divisions from "../divisions.json";

const Get_USER = gql`
  query user {
    user {
      id
      fullName
      mobile
    }
  }
`;

const CREATE_ORDER = gql`
  mutation createOrder($orderInput: OrderInput!) {
    createOrder(orderInput: $orderInput) {
      id
      paymentUrl
    }
  }
`;

export default function CheckPage() {
  return (
    <Box py={10}>
      <CheckForm />
    </Box>
  );
}

function CheckForm() {
  const Alert = useAlert();
  const history = useHistory();
  const LocalStorage = useLocalStorage();
  const useStyles = makeStyles(() => ({}));
  const classes = useStyles();
  const { handleSubmit, control, setValue } = useForm({
    defaultValues: {
      recipient: "",
      recipientPhone: "",
      district: "",
      subdistrict: "",
      address: "",
      shipmentMethod: "CONVENIENCE_STORE",
      storeName: "7-11",
      convenienceStore: "",
      paymentMethod: "Credit",
    },
  });
  const district = useWatch({ control, name: "district" });
  const storeName = useWatch({ control, name: "storeName" });
  const shipmentMethod = useWatch({ control, name: "shipmentMethod" });
  const divisionList = Object.keys(divisions);
  const subdistrictList = district
    ? Object.keys(divisions[district]["subdivisions"])
    : [];

  const { loading } = useQuery(Get_USER, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ user }) {
      if (user) {
        setValue("recipient", user.fullName);
        setValue("recipientPhone", user.mobile);
      }
    },
    onError(error) {
      Alert.notify(`${error}`);
    },
  });

  const [createOrder, { loading: createOrderLoading }] = useMutation(
    CREATE_ORDER,
    {
      onCompleted({ createOrder }) {
        if (createOrder) {
          if (createOrder.paymentUrl) {
            LocalStorage.removeCartId();
            history.replace("/");
            window.location = createOrder.paymentUrl;
          }
        } else {
          return Alert.notify("Failed to create order, please try again!");
        }
      },
      onError(error) {
        return Alert.notify(error.message.replace("GraphQL error: ", ""));
      },
    }
  );

  function _submit(data) {
    const orderInput = {
      cartId: Number(LocalStorage.getCartId()),
      shipmentInput: {
        ...data,
        convenienceStore: data.storeName + " " + data.convenienceStore,
      },
    };
    if (data.shipmentMethod === "CONVENIENCE_STORE") {
      delete orderInput.shipmentInput.storeName;
      delete orderInput.shipmentInput.convenienceStore;
      delete orderInput.shipmentInput.paymentMethod;
    } else {
      delete orderInput.shipmentInput.district;
      delete orderInput.shipmentInput.subdistrict;
      delete orderInput.shipmentInput.address;
      delete orderInput.shipmentInput.storeName;
      delete orderInput.shipmentInput.paymentMethod;
    }
    createOrder({
      variables: { orderInput },
    });
  }

  return (
    <Box>
      <LoadingModal loading={loading || createOrderLoading} />
      <Container maxWidth="sm">
        {/* Select 查詢 */}
        <Box my={2}>
          <Grid container spacing={4}>
            <Grid container item xs={12} justify="center">
              <Typography color="primary" variant="h4">
                Checkout information
              </Typography>
            </Grid>
            <Grid item container spacing={2}>
              {/* 收件人 */}
              <Grid item xs={12}>
                <Typography variant="body2" color="textScondary">
                  Recipient 
                </Typography>
                <Controller
                  name="recipient"
                  control={control}
                  rules={{
                    required: "Recipient is required",
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      placeholder="Please input your Recipient"
                      className={classes.textfield}
                      {...field}
                      error={error}
                      helperText={error && error.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              {/* 收件人電話 */}
              <Grid item xs={12}>
                <Typography variant="body2" color="textScondary">
                  Recipient Mobile
                </Typography>
                <Controller
                  name="recipientPhone"
                  control={control}
                  rules={{
                    required: "Recipient Mobile is required",
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      placeholder="Please input your Recipient Mobile"
                      className={classes.textfield}
                      {...field}
                      error={error}
                      helperText={error && error.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              {shipmentMethod === "CONVENIENCE_STORE" ? (
                <>
                  {/* ANHCOR縣市 */}
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name="district"
                      rules={{
                        required: "District is required",
                      }}
                      render={({
                        field: { onChange, ...otherField },
                        fieldState: { error },
                      }) => (
                        <FormControl
                          size="small"
                          variant="outlined"
                          fullWidth
                          error={error}
                        >
                          <Typography variant="body2" color="textScondary">
                          District
                          </Typography>
                          <Select
                            {...otherField}
                            onChange={(e) => {
                              setValue("subdistrict", "");
                              onChange(e);
                            }}
                            displayEmpty
                          >
                            <MenuItem disabled>Please Select</MenuItem>
                            {divisionList.map((item) => (
                              <MenuItem value={item}>
                                {divisions[item]["zhTW"]}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>
                            {error && error.message}
                          </FormHelperText>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  {/* ANHCOR鄉鎮 */}
                  <Grid item xs={6}>
                    <Controller
                      control={control}
                      name="subdistrict"
                      rules={{
                        required: "Region is required",
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl
                          size="small"
                          variant="outlined"
                          error={error}
                          fullWidth
                        >
                          <Typography variant="body2" color="textScondary">
                          Region
                          </Typography>
                          <Select {...field} disabled={!district} displayEmpty>
                            <MenuItem disabled selected={true}>
                            Please Select
                            </MenuItem>
                            {subdistrictList.map((item) => (
                              <MenuItem value={item}>
                                {
                                  divisions[district]["subdivisions"][item][
                                    "zhTW"
                                  ]
                                }
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>
                            {error && error.message}
                          </FormHelperText>
                        </FormControl>
                      )}
                    />
                  </Grid>

                  {/* 地址 */}
                  <Grid item xs={12}>
                    <Typography variant="body2" color="textScondary">
                    Address
                    </Typography>
                    <Controller
                      name="address"
                      control={control}
                      rules={{
                        required: "Address is required",
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          placeholder="Please input your Address"
                          className={classes.textfield}
                          {...field}
                          error={error}
                          helperText={error && error.message}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  {/* ANHCOR超商 */}
                  <Grid item xs={4}>
                    <Controller
                      control={control}
                      name="storeName"
                      render={({ field }) => (
                        <FormControl size="small" variant="outlined" fullWidth>
                          <Typography variant="body2" color="textScondary">
                          Convenience Store
                          </Typography>
                          <Select {...field} value={storeName} disabled>
                            <MenuItem value={"7-11"}>7-11</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  {/* 門市名稱 */}
                  <Grid item xs={8}>
                    <Typography variant="body2" color="textScondary">
                    Convenience Store Name
                    </Typography>
                    <Controller
                      name="convenienceStore"
                      control={control}
                      rules={{
                        required: "Convenience Store Name is required",
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          placeholder="Please input Convenience Store Name"
                          className={classes.textfield}
                          {...field}
                          error={error}
                          helperText={error && error.message}
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                </>
              )}

              {/* 宅配方式 */}
              <Grid item xs={12}>
                <Typography variant="body2" color="textScondary">
                Delivery method
                </Typography>
                <Controller
                  name="shipmentMethod"
                  control={control}
                  rules={{
                    required: "Delivery method is required",
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl error={error}>
                      <RadioGroup row {...field}>
                        <FormControlLabel
                          value="CONVENIENCE_STORE"
                          control={<Radio />}
                          label="Convenience Store"
                        />
                        <FormControlLabel
                          value="HOME_DELIVERY"
                          control={<Radio />}
                          label="Delivery"
                        />
                      </RadioGroup>
                      <FormHelperText>{error && error.message}</FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>

              {/* 付款方式 */}
              <Grid item xs={12}>
                <Typography variant="body2" color="textScondary">
                Payment method
                </Typography>
                <Controller
                  name="paymentMethod"
                  control={control}
                  rules={{
                    required: "Payment method is required",
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl error={error}>
                      <RadioGroup row {...field}>
                        <FormControlLabel
                          value="Credit"
                          control={<Radio />}
                          label="Credit Card"
                        />
                        <FormControlLabel
                          value="貨到付款"
                          control={<Radio />}
                          label="Cash on delivery"
                        />
                      </RadioGroup>
                      <FormHelperText>{error && error.message}</FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>

              {/* ANHCOR查詢btn */}
              <Grid container item justifyContent="flex-end" xs={12}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit(_submit)}
                  // fullWidth
                >
                  Checkout
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
