import React from "react";
import ReactDOM from "react-dom";
import { ApolloClient } from "apollo-client";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloProvider } from "@apollo/react-hooks";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const link = createUploadLink({
  uri:
    process.env.NODE_ENV == "production"
      ? "https://ltg1l6excl.execute-api.ap-east-1.amazonaws.com/dev/graphql"
      : "https://ltg1l6excl.execute-api.ap-east-1.amazonaws.com/dev/graphql",
      // : "http://localhost:4000/graphql",
});
const authLink = setContext((_, { headers }) => {
  const token =
    localStorage.getItem("@humor3c-webToken") ||
    localStorage.getItem("@humor3c-webConsoleToken");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});
const client = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
