import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Typography,
  Grid,
  makeStyles,
  Button,
  TextField,
  Box,
  useMediaQuery,
  IconButton,
  CircularProgress,
  useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import MinerIcon from "@material-ui/icons/Remove";

import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { useAlert } from "../components/Alert";
import LoadingModal from "../components/LoadingModal";
import { useLocalStorage } from "../components/LocalStorageContext";

const GET_CART = gql`
  query cart($id: Int!) {
    cart(id: $id) {
      id
      items {
        id
        product {
          images {
            location
          }
          name
          price
        }
        unitPrice
        quantity
      }
      totalPrice
    }
  }
`;

const GET_CART_TOTALPRICE = gql`
  query cart($id: Int!) {
    cart(id: $id) {
      id
      totalPrice
    }
  }
`;

const UPDATE_CARTITEMQUANTITY = gql`
  mutation updateCartItemQuantity($cartItemId: Int!, $quantity: Int!) {
    updateCartItemQuantity(cartItemId: $cartItemId, quantity: $quantity)
  }
`;

const REMOVE_ITEMFROMCART = gql`
  mutation removeItemFromCart($id: Int!, $cartItemId: Int!) {
    removeItemFromCart(id: $id, cartItemId: $cartItemId)
  }
`;

export default function CartPage() {
  const Alert = useAlert();
  const theme = useTheme();
  const history = useHistory();
  const LocalStorage = useLocalStorage();
  const useStyles = makeStyles((theme) => ({
    box: {
      height: "auto",
      minHeight: "800px",
      backgroundColor: "#E5E5E5;",
      "& .MuiTypography-h6": {
        fontWeight: "bold",
      },
    },
    listbox: {
      background: "white",
      width: "90%",
      padding: "15px",
      margin: "0px auto 150px",
      borderRadius: "4px",
    },
    containerLoading: {
      height: "50vh",
      alignContent: "center",
    },
  }));
  const classes = useStyles();

  const [getCart, { data, loading }] = useLazyQuery(GET_CART, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onError(error) {
      return Alert.notify(error.message.replace("GraphQL error: ", ""));
    },
  });

  const [getCartTotalPrice, { data: cartTotalPriceData }] = useLazyQuery(
    GET_CART_TOTALPRICE,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      pollInterval: 1000,
      onError(error) {
        return Alert.notify(error.message.replace("GraphQL error: ", ""));
      },
    }
  );

  useEffect(() => {
    if (Boolean(LocalStorage.getCartId())) {
      getCart({
        variables: { id: Number(LocalStorage.getCartId()) },
      });
      getCartTotalPrice({
        variables: { id: Number(LocalStorage.getCartId()) },
      });
    }
  }, [LocalStorage.getCartId()]);

  return (
    <Grid className={classes.box} container>
      <Grid item xs={12} style={{ position: "relative" }}>
        <Grid style={{ margin: "30px" }}>
          <Typography variant="h6">Shopping Cart</Typography>
        </Grid>
        {loading ? (
          <Grid container justify="center" className={classes.containerLoading}>
            <CircularProgress color="secondary" />
          </Grid>
        ) : (
          <>
            {Boolean(data) && data.cart.items[0] ? (
              <>
                <Grid
                  container
                  item
                  xs={12}
                  className={classes.listbox}
                  justifyContent="center"
                >
                  {data?.cart.items.map((item, index) => (
                    <ProductItem
                      key={index}
                      cartItemId={item.id}
                      productImg={item.product.images}
                      productName={item.product.name}
                      productPrice={item.unitPrice}
                      productCount={item.quantity}
                      onRefetch={() =>
                        getCart({
                          variables: {
                            id: Number(LocalStorage.getCartId()),
                          },
                        })
                      }
                    />
                  ))}
                </Grid>
                {data?.cart.items[0] && (
                  <Grid
                    container
                    item
                    xs={12}
                    style={{
                      background: "white",
                      height: "130px",
                      position: "absolute",
                      bottom: 0,
                    }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid
                      item
                      xs={12}
                      sm={11}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="h5" style={{ fontWeight: "bold" }}>
                      Total:
                      </Typography>
                      <Typography
                        variant="h5"
                        color="primary"
                        style={{ fontWeight: "bold" }}
                      >
                        ${cartTotalPriceData?.cart.totalPrice}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={1}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          if (LocalStorage.getMemberToken()) {
                            history.push("/check");
                          } else {
                            Alert.alert("", "Please login first!", [
                              {
                                text: "Login",
                                onClick: () => history.push("/log-in"),
                              },
                              { text: "Cancel", type: "outlined" },
                            ]);
                          }
                        }}
                      >
                        Checkout
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </>
            ) : (
              <Grid
                container
                justify="center"
                className={classes.box}
                alignContent="center"
              >
                <Typography
                  variant="h6"
                  style={{
                    color: theme.palette.primary.main,
                  }}
                >
                  There are no items in the shopping cart
                </Typography>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
}

function ProductItem({
  cartItemId,
  productImg,
  productName,
  productPrice,
  productCount,
  onRefetch = () => {},
}) {
  const Alert = useAlert();
  const LocalStorage = useLocalStorage();
  const isPhone = useMediaQuery("(max-width:600px)");
  const useStyles = makeStyles((theme) => ({
    countBox: {
      marginBottom: isPhone ? "15px" : "0px",
      "& .MuiInputBase-root": {
        padding: "0",
      },
      "& .MuiInputBase-input": {
        textAlign: "center",
        margin: 0 + " !important",
      },
    },
    textfield: {
      "& .MuiOutlinedInput-inputMarginDense": {
        padding: "0",
      },
      "& .MuiOutlinedInput-input": {
        padding: "0",
      },
    },
    countBtn: {
      minWidth: "50px",
      cursor: "pointer",
      color: "#fff",
      backgroundColor: theme.palette.grey[400],
      "&:hover": {
        backgroundColor: theme.palette.grey[300],
      },
    },
    cssOutlinedInput: {
      "&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline":
        {
          border: "2px solid",
          borderColor: "#bdbdbd",
        },
      "&$cssFocused $notchedOutline": {
        // border: '1px solid',
        borderColor: "#bdbdbd",
      },
    },
    notchedOutline: {},
    cssFocused: {},
  }));
  const classes = useStyles();
  const [count, setCount] = useState(productCount);

  const [updateCartItemQuantity, { loading: updateCartItemQuantityLoading }] =
    useMutation(UPDATE_CARTITEMQUANTITY, {
      onCompleted({ updateCartItemQuantity }) {
        if (updateCartItemQuantity) {
          return null;
        } else {
          return Alert.notify("Failed to change the shopping cart quantity, please try again!");
        }
      },
      onError(error) {
        return Alert.notify(error.message.replace("GraphQL error: ", ""));
      },
    });

  const [removeItemFromCart, { loading: removeItemFromCartLoading }] =
    useMutation(REMOVE_ITEMFROMCART, {
      onCompleted({ removeItemFromCart }) {
        if (removeItemFromCart) {
          return onRefetch();
        } else {
          return Alert.notify("Failed to delete shopping cart, please try again!");
        }
      },
      onError(error) {
        return Alert.notify(error.message.replace("GraphQL error: ", ""));
      },
    });

  return (
    <Grid
      container
      item
      xs={11}
      style={{
        height: "auto",
        borderBottom: "2px solid #9E9E9E",
        minHeight: "150px",
        marginBottom: "20px",
      }}
      alignItems="center"
    >
      <LoadingModal loading={removeItemFromCartLoading} />
      <Grid
        item
        xs={12}
        sm={2}
        style={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <Grid
          style={{
            display: isPhone ? "block" : "none",
            position: "absolute",
            right: -5,
            top: -5,
          }}
        >
          <IconButton>
            <CloseIcon style={{ color: "white" }} />
          </IconButton>
        </Grid>
        <img
          src={productImg && productImg[0]?.location}
          alt=""
          style={{ width: isPhone ? "100%" : "100px" }}
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <Grid>
          <Typography
            variant="h4"
            style={{ fontWeight: "bold", marginBottom: "20px" }}
          >
            {productName}
          </Typography>
        </Grid>
        <Grid>
          <Typography
            variant="h4"
            color="primary"
            style={{ fontWeight: "bold" }}
          >
            ${productPrice}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Box className={classes.countBox}>
          <TextField
            fullWidth
            value={count}
            className={classes.textfield}
            margin="false"
            InputProps={{
              readOnly: true,
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              },
              startAdornment: (
                <Button
                  size="small"
                  className={classes.countBtn}
                  onClick={() => {
                    if (count <= 1) {
                      return null;
                    } else {
                      setCount((e) => {
                        updateCartItemQuantity({
                          variables: { cartItemId, quantity: e - 1 },
                        });
                        return e - 1;
                      });
                    }
                  }}
                  disabled={updateCartItemQuantityLoading}
                >
                  <MinerIcon position="start" />
                </Button>
              ),
              endAdornment: (
                <Button
                  size="small"
                  className={classes.countBtn}
                  onClick={() => {
                    setCount((e) => {
                      updateCartItemQuantity({
                        variables: { cartItemId, quantity: e + 1 },
                      });
                      return e + 1;
                    });
                  }}
                  disabled={updateCartItemQuantityLoading}
                >
                  <AddIcon position="end" />
                </Button>
              ),
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              width: "100%",
            }}
          />
        </Box>
      </Grid>
      <Grid
        xs={12}
        sm={2}
        style={{ display: isPhone ? "none" : "flex", justifyContent: "center" }}
      >
        <IconButton
          onClick={() => {
            Alert.alert("", "Are you sure you want to delete?", [
              {
                text: "Yes",
                onClick: () => {
                  removeItemFromCart({
                    variables: {
                      id: Number(LocalStorage.getCartId()),
                      cartItemId,
                    },
                  });
                },
              },
              { text: "Cancel", type: "outlined" },
            ]);
          }}
        >
          <DeleteIcon style={{ fontSize: "36px" }} color="error" />
        </IconButton>
      </Grid>
    </Grid>
  );
}
