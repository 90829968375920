import React from "react";
import {
  Typography,
  Grid,
  makeStyles,
  TextareaAutosize,
  Button,
  Box,
  Container,
} from "@material-ui/core";

export default function ContactUsPage() {
  const useStyles = makeStyles((theme) => ({
    box: {
      height: "auto",
      maxWidth: "1200px",
      margin: "0 auto",

      "& .MuiTypography-h4": {
        display: "inline-block",
        fontWeight: "bold",
        borderBottom: "3px solid #FFB506",
        margin: "50px auto",
      },
    },
  }));
  const classes = useStyles();

  const contactUs = [
    "Our Phone customer service hours : ",
    "Monday to Friday from 9:00 to 20:00 GMT+8",
    "IF you want to contact us you can call us at: +886-2-2706-0866",
    "However , you can find us anytime by the messenger APP 'LINE' ; scan the followed QRCODE and it'll run you through. Enter the requested information afterwards so that we can deal with your enquiry as quickly and efficiently as possible.",
    "OUR ADDRESS :",
    "37F., No. 7, Sec. 5, Xinyi Rd., Xinyi Dist., Taipei City ,ZIP110013 , Taiwan (R.O.C.)",
  ];
  return (
    <Container bgcolor="#fff">
      <Grid
        className={classes.box}
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={8} md={5}>
          <Box display="flex" justifyContent="center">
            <Typography variant="h4">CONTACT US</Typography>
          </Box>
          {contactUs.map((item) => {
            return (
              <Typography variant="body1" gutterBottom>
                {item}
              </Typography>
            );
          })}
          <Box align="center">
            <img src="/assets/images/qrcode.jpg" alt="QRcode" />
          </Box>
          <Grid item xs={11} style={{ margin: "50px auto 30px" }}>
            <TextareaAutosize
              minRows={10}
              style={{ width: "100%", resize: "none" }}
            />
          </Grid>
          <Grid item xs={11} style={{ margin: "0px auto 150px" }}>
            <Button color="primary" variant="contained">
              submit
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
