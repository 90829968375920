import React, { createElement, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";

import Layout from "./components/Layout";
import { CssBaseline } from "@material-ui/core";
import HomePage from "./pages/HomePage.js";
import AboutPage from "./pages/AboutPage.js";
import GuidePage from "./pages/GuidePage.js";
import ReturnExChangePage from "./pages/ReturnExChangePage.js";
import ContactUsPage from "./pages/ContactUsPage.js";
import FAQPage from "./pages/FAQPage.js";
import LoginPage from "./pages/LoginPage.js";
import RegisterPage from "./pages/RegisterPage.js";
import ForgotPasswordPage from "./pages/ForgotPasswordPage.js";
import ProductListPage from "./pages/ProductListPage.js";
import ProductPage from "./pages/ProductPage.js";
import CartPage from "./pages/CartPage.js";
import CheckPage from "./pages/CheckPage";

import { CreateCart } from "./components/CreateCart";

export default function RouteComponent() {
  CreateCart();
  const routeList = [
    { path: "/", component: HomePage },
    { path: "/about", component: AboutPage },
    { path: "/guide", component: GuidePage },
    { path: "/return-exchange", component: ReturnExChangePage },
    { path: "/contact-us", component: ContactUsPage },
    { path: "/question", component: FAQPage },
    { path: "/log-in", component: LoginPage },
    { path: "/register", component: RegisterPage },
    { path: "/forgot-password", component: ForgotPasswordPage },
    { path: "/product-list/:typeName", component: ProductListPage },
    { path: "/product/:id", component: ProductPage },
    { path: "/cart", component: CartPage },
    { path: "/check", component: CheckPage },
  ];

  return (
    <>
      <CssBaseline />
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path={routeList.map((item) => item.path)}>
            <Layout>
              {routeList.map(({ path, component }) => (
                <Route key={path} path={path} exact>
                  {createElement(component)}
                </Route>
              ))}
            </Layout>
          </Route>
        </Switch>
      </Router>
    </>
  );
}

// ANCHOR 換頁重置滾動
function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}
