import React from "react";
import Route from "./Route";
import ThemeProvider from "./ThemeProvider.js";
import { LocalStorageContextProvider } from "./components/LocalStorageContext";
import { AlertProvider } from "./components/Alert";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
// import MomentUtils from "@date-io/moment";
// import LuxonUtils from "@date-io/luxon";

export default function App() {
  return (
    <ThemeProvider>
      <LocalStorageContextProvider>
        <AlertProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Route />
          </MuiPickersUtilsProvider>
        </AlertProvider>
      </LocalStorageContextProvider>
    </ThemeProvider>
  );
}
