import React from 'react'
import {
  Typography,
  Grid,
  makeStyles,
  Box
} from '@material-ui/core'

export default function FQAPage() {
  const useStyles = makeStyles((theme) => ({
    box:{
        height: "auto",
        backgroundColor: 'white',
        '& .MuiTypography-h4':{
          display:'inline-block',
          fontWeight:'bold',
          borderBottom:'3px solid #FFB506',
          margin:'50px auto'
        }
    },
    }));

    const faqList = [
      {
        title:'1. Where is HUMORCORP located?',
        answer:'Our facilities are in Taipei, TAIWAN. Where lots of well-known brands are born such as ASUS,TREND MICRO,Gigabyte,Coolermaster,Advantech,Acer,MediaTek,Delta,TRANSCEND,MSI  …and so much more .This is where we process orders and from where we ship the items available on our website.'
      },
      {
        title:'2. Does HUMORCORP sell its products through marketplaces or other sites?',
        answer:'No. At HUMORCORP we sell our products exclusively through our website www.HUMORCORP.com.'
      },
      {
        title:'3. Where do you ship to?',
        answer:'We ship our products regularly to any part of the world, for more shipping info. Check out our shipping policy page. Or you can check with our customer service.'
      },
      {
        title:'4. What payment methods are available on HUMORCORP?',
        answer:'At HUMORCORP we have the following payment methods, which will be available depending on the country from which you send your purchase, mostly Credit or debit card are required, you’ll see the payment methods upon checking out. '
      },
      {
        title:'5. What is the warranty period for purchases from HUMORCORP?',
        answer:'Your purchases at HUMORCORP have a 2-year warranty except for refurbished products, which have a 1-year warranty, and products that are subject to wear and tear during use because of their nature, which have a 6-month warranty. For more accurate information Check out our return policy page.'
      },
      {
        title:'6. How can I contact HUMORCORP?',
        answer:'You can contact us whenever you need to via the help button in the bottom right-hand corner of the contact section of our website.  You can also call us on phone. +886-2-2706-0866 Our Phone customer service hours: Monday to Friday from 9:00 to 20:00 GMT+8'
      },
    ];

  const classes = useStyles();
    return (
      <Grid
        className={classes.box}
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={11}  style={{ marginBottom: "50px"}}>
          <Box display="flex" justifyContent="center" >
            <Typography variant="h4">FAQ</Typography>
          </Box>
          {
            faqList.map((item)=>{
              return(
              <Grid>
                <Typography variant="h6" style={{fontWeight:'bold'}}>{item.title}</Typography>
                <Typography variant="h6" style={{ marginBottom: "30px"}}>{item.answer}</Typography>
              </Grid>
              )
            })
          }
          </Grid>
      </Grid>
    );
}