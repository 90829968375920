import React from "react";
import {
  Typography,
  Grid,
  makeStyles,
  useTheme,
  Box,
  Container,
  useMediaQuery,
} from "@material-ui/core";
// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Autoplay,
  Virtual,
} from "swiper/core";
import "swiper/swiper-bundle.css";
SwiperCore.use([Navigation, Pagination, Autoplay, Virtual]);

export default function AboutPage() {
  const isPhone = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:960px)");
  const useStyles = makeStyles((theme) => ({
    box: {
      "& .MuiTypography-h4": {
        display: "inline-block",
        fontWeight: "bold",
        borderBottom: "3px solid #FFB506",
        margin: "50px auto",
      },
    },
  }));
  const classes = useStyles();

  const aboutContent = [
    "HUMORCORP is a top tier distributor of consumer electronics.",
    "We proudly offer a full line of complementary consumer electronics accessories.",
    "With more than three years of distribution we proudly launch our on-line service to serve our premium distributer customers with best quality product and service.",
    "We provide genuine products only ,with a wide range of international brands and its official warranty.",
  ];

  return (
    <Box bgcolor="#fff">
      <Container maxWidth="sm">
        <Grid
          className={classes.box}
          container
          spacing={2}
          justifyContent="center"
        >
          <Grid item xs={11}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography variant="h4">ABOUT US</Typography>
            </Grid>
            {aboutContent.map((item) => {
              return (
                <Typography variant="body1" style={{ marginBottom: "15px" }}>
                  {item}
                </Typography>
              );
            })}
          </Grid>
          <Grid item xs={12}>
            <Box mb={6} mt={2}>
              <AboutCarousel />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

// carousel

function AboutCarousel() {
  const isTablet = useMediaQuery("(max-width:960px)");
  const isSmPhone = useMediaQuery("(max-width:380px)");
  const useStyles = makeStyles({
    swiperBox: {
      maxHeight: "500px",
      width: isTablet ? "100%" : "70%",
      "& .swiper-pagination-bullet": {
        width: "12px",
        height: "12px",
      },
      "& .swiper-pagination-bullet+.swiper-pagination-bullet": {
        marginLeft: "5px",
      },
      "& .swiper-pagination": {
        bottom: "0" + "!important",
        left: "50%" + "!important",
        transform: "translateX(-50%)" + "!important",
        width: "100%",
      },
      "& .swiper-button-prev:after": {
        fontSize: "24px",
        padding: "5px",
        color: isSmPhone ? "#fff" : "",
      },

      "& .swiper-button-next:after": {
        fontSize: "24px",
        padding: "5px",
        color: isSmPhone ? "#fff" : "",
      },
    },
  });
  const classes = useStyles();
  const slideImages = [
    "/assets/images/About/about (1).jpg",
    "/assets/images/About/about (2).jpg",
    "/assets/images/About/about (3).jpg",
    "/assets/images/About/about (4).jpg",
    "/assets/images/About/about (5).jpg",
    "/assets/images/About/about (6).jpg",
    "/assets/images/About/about (7).jpg",
    "/assets/images/About/about (8).jpg",
    "/assets/images/About/about (9).jpg",
    "/assets/images/About/about (10).jpg",
    "/assets/images/About/about (11).jpg",
    "/assets/images/About/about (12).jpg",
    "/assets/images/About/about (13).jpg",
    "/assets/images/About/about (14).jpg",
    "/assets/images/About/about (15).jpg",
    "/assets/images/About/about (16).jpg",
    "/assets/images/About/about (17).jpg",
    "/assets/images/About/about (18).jpg",
    "/assets/images/About/about (19).jpg",
    "/assets/images/About/about (20).jpg",
    "/assets/images/About/about (21).jpg",
    "/assets/images/About/about (22).jpg",
    "/assets/images/About/about (23).jpg",
    "/assets/images/About/about (24).jpg",
    "/assets/images/About/about (25).jpg",
    "/assets/images/About/about (26).jpg",
  ];
  return (
    <div>
      <Swiper
        id="swiper"
        slidesPerView={1}
        autoplay={true}
        loop
        navigation
        pagination={{ clickable: true }}
        className={classes.swiperBox}
      >
        {slideImages.map((item) => {
          return (
            <SwiperSlide>
              <div style={{ borderRadius: "30px" }}>
                <img
                  src={item}
                  style={{
                    width: "100%",
                    objectFit: "contain",
                    height: "450px",
                  }}
                />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
