import React from "react";
import {
  Typography,
  Grid,
  makeStyles,
  Box,
  withStyles,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    minWidth: 200,
    width: "200px",
    maxWidth: 200,
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function ReturnExChangePage() {
  const useStyles = makeStyles((theme) => ({
    box: {
      height: "auto",
      backgroundColor: "white",
      "& .MuiTypography-h4": {
        display: "inline-block",
        fontWeight: "bold",
        borderBottom: "3px solid #FFB506",
        margin: "50px auto",
      },
    },
  }));
  const classes = useStyles();

  function createData(name, value) {
    return { name, value };
  }

  const rows = [
    createData("Canada", "$17.99"),
    createData("Mexico", "$14.99"),
    createData("Latin America", "$18.99"),
    createData("Atlantic,Carribean & Other Islands", "$21.99"),
    createData("Europe", "$24.99"),
    createData("Israel", "$18.99"),
    createData("Middle East", "$14.99"),
    createData("Africa", "$19.99"),
    createData("Australia", "$20.99"),
    createData("Japan", "$17.99"),
    createData("Asia & Pacific Islands", "$9.99"),
    createData("Russian Federation", "n/a"),
  ];

  return (
    <Grid
      className={classes.box}
      container
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={11}>
        <Box display="flex" justifyContent="center">
          <Typography variant="h4">Return & Cancellation</Typography>
        </Box>
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          1. Shipping info:
        </Typography>
        <Typography variant="h6">
          Estimated time of delivery 15 to 30 days from the date of technically
          and commercially clear order and acceptance.
          <br />
          <br />
          Shipping charges will be extra otherwise mentioned clearly. Dispatch
          of goods will be through reputed couriers. Most of the items are
          shipped within 24-48 working hours after the payment is cleared.
          Delivery of goods will be made only on weekdays and working days. No
          delivery during weekends, holidays and after office hours. HUMORCORP
          has rights to cancel order at any circumstance. Shipping information
          will be provided on request only. Shipping and handling fees are
          non-refundable. *For Next Day Delivery, Order placed within 3.00 PM.
          <br />
          <br />
          HUMORCORP works closely with its carriers to provide a competitive
          shipping experience for our customers. Exact shipping charges are
          calculated at the time of checkout based on the number of items, item
          type, weight, and volume of all the items in the cart.
          <br />
          <br />
          PLEASE NOTE that total shipping cost is calculated by adding a base
          "Per Shipment" cost and various "Per Item" costs. The "Per Item" cost
          depends on the item, and is based on the quantity of items, the
          weight, or volume (referred to as the dimensional weight) of each
          package. AND Shipping and handling fees are non-refundable.
          <br />
          <br />
          The table below shows the base “Per Shipment” rate used as a minimum
          for shipping. Rates increase, or vary, from the base rate depending on
          the items in the cart.
          <br />
          <br />
        </Typography>
        <Box mb={2}>
          <TableContainer component={Paper} style={{ width: 290 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell width="50%">Region</StyledTableCell>
                  <StyledTableCell>
                    Standard Shipping Per shipment(starting from)
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((item) => (
                  <StyledTableRow>
                    <StyledTableCell>{item.name}</StyledTableCell>
                    <StyledTableCell>{item.value}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          2. Applicable taxes:
        </Typography>
        <Typography variant="h6">
          Unless otherwise stated, all prices exclude VAT / TAX as applicable in
          TAIWAN only.
          <br />
          <br />
        </Typography>
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          3. Warranty:
        </Typography>
        <Typography variant="h6">
          Mobile handsets & chargers are warranted for a period defined by the
          respective manufacture’s agents defect in material & workmanship.
          HUMORCORP is not giving the warranty and does not hold out any
          warranty of products sold. HUMORCORP will not be responsible for any
          defective / deficient or otherwise unsatisfactory products any such
          defective or deficient goods must be repaired only by authorized
          service center of the equipment manufacturer. All the terms and
          conditions of HUMORCORP apply. Goods once sold cannot be returned or
          exchanged.
          <br />
          <br />
        </Typography>
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          4. Return
        </Typography>
        <Typography variant="h6">
          The Product was purchased in the last 30 days
          <br />
          <br />
          The Product is in its original packaging (intact) and no seal is
          broken (unopened) Reason for return has to be valid and return a
          acceptance conditions (check on below)
          <br />
          <br />
          Delivery of wrong item
          <br />
          <br />
          Delivery of faulty Product
          <br />
          <br />
          Delivery of products with missing parts
          <br />
          <br />
          Incorrect content on the website
          <br />
          <br />
        </Typography>
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          5. CANCELLATION & REFUND DUE TO PRODUCT UNAVAILABILITY
        </Typography>
        <Typography variant="h6">
          Delivery of the ordered items is subject to availability of the
          products at our warehouse/store. GadgetandGear.com authority may
          cancel any order within 10 working days if the stock of the ordered
          item(s) is temporarily unavailable. If the customer agrees to wait for
          the availability of the stock of the product (s), the order may remain
          in open/processing/hold status for a maximum of 30 days. In the case
          of clause (a), if the customer has already made an advance payment
          against the order, the customer will receive a full refund, if not
          received any cashback, within 1 - 15 working days. Received Cashback
          amount will be adjusted with the refund amount.
          <br />
          <br />
          After confirming any Order, if the order is cancelled by the customer
          and an online payment is already made by the customer against that
          order, GadgetandGear.com will charge an Online Gateway Transaction fee
          while disbursing the refund amount.
          <br />
          <br />
        </Typography>
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          6. RETURN/REPLACEMEN T GUARANTEE MAY NOT APPLY IN ANY OF THE FOLLOWING
          CONDITIONS
        </Typography>
        <Typography variant="h6">
          Damages due to misuse of the product
          <br />
          <br />
          Incidental damage du e to malfunctioning of product
          <br />
          <br />
          Any consumable item which has been used or installed
          <br />
          <br />
          Products with tampered or missing serial / UPC numbers
          <br />
          <br />
          Any damage/defect which is not covered und er the manufacturer's
          warranty
          <br />
          <br />
          Any product that is returned without all original packaging and
          accessories, including the box, manufacturer's packaging. If any, and
          all other items originally included with the product(s) delivered.
          <br />
          <br />
        </Typography>
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          7. Damaged items
        </Typography>
        <Typography variant="h6">
          If you received a damaged product, please notify us immediately for
          assistance.
          <br />
          <br />
        </Typography>
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          8. Sale items
        </Typography>
        <Typography variant="h6">
          Unfortunately, sale items cannot be refunded. Only regular price items
          can be refunded.
          <br />
          <br />
        </Typography>
      </Grid>
    </Grid>
  );
}
