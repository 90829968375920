import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  makeStyles,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  Select as Select2,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { useAlert } from "./Alert";
import Button from "./Button";
import { Select } from "./Form";
import LoadingModal from "./LoadingModal";
import divisions from "../divisions.json";

const Get_USER = gql`
  query user {
    user {
      id
      fullName
      mobile
    }
  }
`;

export default function BuyFloatingLayer({
  open,
  content,
  onChangeFloatingwindowClose = () => {},
}) {
  const Alert = useAlert();
  const useStylesTable = makeStyles((theme) => ({
    QuestionsContainer: {
      flexDirection: "column",
    },
  }));
  const [paymentMethod, setPaymentMethod] = useState("");
  const [receiverName, setReceiverName] = useState("");
  const [receiverCellPhone, setReceiverCellPhone] = useState(null);
  const [division, setDivision] = useState("");
  const [subdivision, setSubdivision] = useState("");
  const [address, setAddress] = useState("");
  const [convenienceStore, setConvenienceStore] = useState("7-11");
  const [convenienceStoreText, setConvenienceStoreText] = useState("");
  const [shippingmethod, setShippingmethod] = useState("CONVENIENCE_STORE");
  const classesTable = useStylesTable();
  const divisionKey = Object.keys(divisions);
  const subdivisionsKey =
    division && Object.keys(divisions[division].subdivisions);
  const ShippingmethodArray = [
    { title: "Convenience Store", value: "CONVENIENCE_STORE" },
    { title: "Delivery", value: "HOME_DELIVERY" },
  ];
  const subdivisionRef = useRef("");
  const convenienceStoreArray = [
    {
      label: "7-11",
      value: "7-11",
    },
  ];

  const [getUser, { loading }] = useLazyQuery(Get_USER, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted({ user }) {
      if (user) {
        setReceiverName(user.fullName);
        setReceiverCellPhone(user.mobile);
      }
    },
    onError(error) {
      Alert.notify(`${error}`);
    },
  });

  useEffect(() => {
    if (open) {
      getUser();
    } else {
      setPaymentMethod("");
      setDivision("");
      setSubdivision("");
      setAddress("");
      setConvenienceStoreText("");
      setShippingmethod("CONVENIENCE_STORE");
    }
  }, [open]);

  useEffect(() => {
    if (Boolean(subdivisionRef.current)) {
      setSubdivision(subdivisionRef.current);
    } else {
      setSubdivision("");
    }
  }, [division]);

  function initialization(value, paymentMethod, shipmentInput) {
    onChangeFloatingwindowClose(value, paymentMethod, shipmentInput);
  }

  function _createShipment() {
    if (!Boolean(paymentMethod)) {
      return Alert.alert("notify", "Please select a payment method!", [
        {
          text: "determine",
        },
      ]);
    }
    if (!Boolean(receiverName)) {
      return Alert.notify("The recipient's name is not filled in!");
    }

    if (!Boolean(receiverCellPhone)) {
      return Alert.notify(
        "The recipient's mobile phone name is not filled in!"
      );
    }

    if (Boolean(shippingmethod === "CONVENIENCE_STORE")) {
      if (!Boolean(convenienceStoreText)) {
        return Alert.notify("The receiving store is not filled in!");
      }
    }

    if (Boolean(shippingmethod === "HOME_DELIVERY")) {
      if (!Boolean(division)) {
        return Alert.notify(
          "The recipient county or city has not been selected!"
        );
      }

      if (!Boolean(subdivision)) {
        return Alert.notify("The recipient area is not selected!");
      }

      if (!Boolean(address)) {
        return Alert.notify("The recipient address is not filled in!");
      }
    }

    if (shippingmethod === "CONVENIENCE_STORE") {
      initialization("mutation", paymentMethod, {
        recipient: receiverName,
        recipientPhone: receiverCellPhone,
        shipmentMethod: shippingmethod,
        convenienceStore: `${convenienceStore}${" "}${convenienceStoreText}`,
      });
    } else {
      initialization("mutation", paymentMethod, {
        recipient: receiverName,
        recipientPhone: receiverCellPhone,
        shipmentMethod: shippingmethod,
        district: division,
        subdistrict: subdivision,
        address,
      });
    }
  }

  return (
    <>
      {/* 新版顯示畫面 */}
      <Dialog
        style={{ marginBottom: "5em" }}
        open={open}
        onClose={initialization}
        fullWidth
        disableScrollLock
      >
        <LoadingModal loading={false} />
        <DialogTitle>We currently don't sell in your region</DialogTitle>
        {loading ? (
          <Grid container item justify="center">
            <CircularProgress color="secondary" />
          </Grid>
        ) : (
          <>
            <DialogContent>
              <DialogContentText>
                Please contact our customer service online for further
                explanation
              </DialogContentText>
              <picture>
                <source srcset="/assets/images/qrcode.webp" type="image/webp" />
                <img src="/assets/images/qrcode.jpg" alt="" />
              </picture>
            </DialogContent>
          </>
        )}
      </Dialog>
      {/* 舊版顯示畫面 */}
      {/* <Dialog
      style={{ marginBottom: "5em" }}
      open={open}
      onClose={initialization}
      fullWidth
      disableScrollLock
    >
      <LoadingModal loading={false} />
      <DialogTitle>notify</DialogTitle>
      {loading ? (
        <Grid container item justify="center">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <>
          <DialogContent>
            <Grid container direction="column" spacing={1}>
              {content && (
                <Grid item>
                  <Typography color="primary">{content}</Typography>
                </Grid>
              )}
              <Grid item>
                <Typography color="primary">choose to pay by cash:</Typography>
                <Select
                  items={[
                    {
                      label: "Credit Card",
                      value: "Credit",
                    },
                    {
                      label: "ATM",
                      value: "ATM",
                    },
                  ]}
                  value={paymentMethod}
                  onChange={(v) => setPaymentMethod(v)}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Typography color="primary">Shipping method</Typography>
                <FormGroup row>
                  {ShippingmethodArray.map((item, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          name={item.value}
                          checked={Boolean(shippingmethod === item.value)}
                          onChange={() => setShippingmethod(item.value)}
                        />
                      }
                      label={item.title}
                    />
                  ))}
                </FormGroup>
              </Grid>
              <Grid
                container
                item
                spacing={1}
                classes={{ root: classesTable.QuestionsContainer }}
              >
                <Grid item>
                  <Typography color="primary">The recipient's name</Typography>
                  <TextField
                    value={receiverName}
                    onChange={(e) => setReceiverName(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <Typography color="primary">Recipient's mobile phone</Typography>
                  <TextField
                    value={receiverCellPhone}
                    onChange={(e) => setReceiverCellPhone(e.target.value)}
                    fullWidth
                  />
                </Grid>
                {shippingmethod === "CONVENIENCE_STORE" ? (
                  <Grid item>
                    <Typography color="primary">Receiving store</Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={3}>
                        <Select2
                          value={convenienceStore}
                          fullWidth
                          onChange={(e) => setConvenienceStore(e.target.value)}
                          displayEmpty
                          disabled
                        >
                          {convenienceStoreArray.map((item) => (
                            <MenuItem value={item.value} key={item}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select2>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <TextField
                          placeholder={"Please enter the store ex: xx store"}
                          value={convenienceStoreText}
                          onChange={(e) =>
                            setConvenienceStoreText(e.target.value)
                          }
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item>
                    <Typography color="primary">receiver's address</Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={3}>
                        <Select2
                          value={division}
                          fullWidth
                          onChange={(e) =>
                            setDivision(() => {
                              subdivisionRef.current = "";
                              return e.target.value;
                            })
                          }
                          displayEmpty
                        >
                          <MenuItem value="" disabled>
                            Counties
                          </MenuItem>
                          {divisionKey.map((item) => (
                            <MenuItem value={item} key={item}>
                              {divisions[item].zhTW}
                            </MenuItem>
                          ))}
                        </Select2>
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <Select2
                          value={subdivision}
                          fullWidth
                          onChange={(e) => setSubdivision(e.target.value)}
                          displayEmpty
                        >
                          <MenuItem value="" disabled>
                            region
                          </MenuItem>
                          {Boolean(division) &&
                            subdivisionsKey.map((item) => (
                              <MenuItem key={item} value={item}>
                                {divisions[division].subdivisions[item].zhTW}
                              </MenuItem>
                            ))}
                        </Select2>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container spacing={1} justify="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onPress={() => _createShipment()}
                >
                  determine
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onPress={() => initialization()}
                >
                  Stay on this page
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </>
      )}
    </Dialog> */}
    </>
  );
}
