export const emailPattern = /^[\w\.]+@\w+((\.|-)\w+)+$/;
export const verifyPattern = /^[0-9]{6}$/;
export const passwordPattern =
  /^(?=.*\d)(?=.*[a-zA-Z])(?!.*[^\x00-\xff]).{8,}.*$/;
export const mobilePattern = /^[0-9]{10}$/;
export const serialNumberPattern = /^TW[0-9]{7}$/;

export function addThousandSeparator(number) {
  const reg = /\d{1,3}(?=(\d{3})+$)/g;
  return String(number).replace(reg, "$&,");
}
export function removeThousandSeparator(number) {
  return String(number).replace(",", "");
}
