import React from 'react'
import {
  Typography,
  Grid,
  makeStyles,
  Box
} from '@material-ui/core'

export default function GuidePage() {
  const useStyles = makeStyles((theme) => ({
    box:{
        height: "auto",
        backgroundColor: 'white',
        '& .MuiTypography-h4':{
          display:'inline-block',
          fontWeight:'bold',
          borderBottom:'3px solid #FFB506',
          margin:'50px auto'
        }
    },
    }));
  const classes = useStyles();
    return (
      <Grid
        className={classes.box}
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={11}>
          <Box display="flex" justifyContent="center">
            <Typography variant="h4" align="center">
              Shopping Policy
            </Typography>
          </Box>
          <Typography variant="h6" gutterBottom style={{ fontWeight: "bold" }}>
            1. Trademarks:
          </Typography>
          <Typography variant="h6">
            HUMORCORP is our trademarks and service mark. All other trademarks, product names, and company names and logos appearing on the site are the property of their respective owners.
            <br />
            <br />
          </Typography>
          <Typography variant="h6" gutterBottom style={{ fontWeight: "bold" }}>
            2. Submissions:
          </Typography>
          <Typography variant="h6">
          If you submit, post or otherwise send us any information, content or materials including, without limitation, comments, reviews, data, text, messages, files, images, photographs, videos, audiovisual works, other persons' names, likenesses, voices, usernames, profiles, actions, appearances, performances and/or other biographical information or material, and any other materials, as well as links to data, text, files, images, photographs, videos, audiovisual works, shall be entitled to unrestricted use of such submissions for any purpose at all, commercial or otherwise, without the requirement of any further permission from or payment to you or to any other person or entity, including without limitation for inclusion in any future gale publication or gale product, with no compensation to you.
No submission shall be subject to any obligation of confidentiality on our part, and we shall not be liable for any use or disclosure of any submission. Without limiting the previous, you hereby grant us.
            <br /> <br />
          </Typography>
          <Typography variant="h6" gutterBottom style={{ fontWeight: "bold" }}>
          3. Payment and billing:
          </Typography>
          <Typography variant="h6" style={{ marginBottom: "100px" }}>
          You must select a payment method to pay us for any purchases you make from us. Every time you use the service, you reaffirm that. We or our authorized billing agents are authorized to charge your designated payment method.
We may submit charges incurred under your account for payment; and you will be responsible for such charges, even if your membership is cancelled or terminated. You agree that we may charge your payment method for all amounts due to us without additional notice or consent unless required otherwise by law.
You are responsible for all charges incurred under your account made by you or anyone who uses your account (including your children, family or friends).
We may, in our discretion, post charges to your payment method individually or may aggregate your charges with other purchases you make on the site and apply those charges to your next billing cycle. All purchases for mobile, services, accessories, and all subscriptions are final and nonrefundable.
You must notify us about any billing problems or discrepancies within 3 days after they first appear on the statement you receive from your bank or Credit Card Company.
If you do not bring such problems or discrepancies to our attention within 3 days, you agree that you waive the right to dispute such problems or discrepancies.
In case of Order cancellation, the mode of payment will be done by transferring through Port-wallet 14 working days. ( i.e we can't offer your payment as cash ).
All bank discount/cashback offers will be subjected to direct purchase. Any bank discount/cashback offers won't be applicable while using EMI facility.
          </Typography>
          <Typography variant="h6" gutterBottom style={{ fontWeight: "bold" }}>
          4. Proof of identification:
          </Typography>
          <Typography variant="h6" style={{ marginBottom: "100px" }}>
          HUMORCORP., reserves the right to ask for defined proof of identification at the time of delivery. Failure to provide the same to either the HUMORCORP or any of its associates, can lead to cancellation of the order. In case of any gift items, customer who has made the transaction online will have to provide his/her proof of identification.
          </Typography>
          <Typography variant="h6" gutterBottom style={{ fontWeight: "bold" }}>
          5. Limitation of liability:
          </Typography>
          <Typography variant="h6" style={{ marginBottom: "100px" }}>
          You hereby agree to fully identify, defend, and hold our company and its parent and affiliates and their respective officers, directors, employees and licensors harmless from and against any and all claims, liability, losses, costs and expenses (including attorneys' fees) incurred by you in connection with:<br /><br />
(I) Any use or alleged use of HUMORCORP / services through your account by any person, whether authorized by you.<br /><br />
(II) The operation and content on your site or<br /><br />
(III) Any breach of your representations and warranties and other covenants under this agreement<br /><br />
HUMORCORP reserves the right, at its own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, and in such case, and agree to cooperate with HUMORCORP defense of such claim.
Under no circumstances and under no legal theory, tort, contract, strict liability, or otherwise, shall the mobile store(s)/content producer or HUMORCORP be liable to you or any other person for any indirect, special, incidental, or consequential damages of any character including, without limitation, damages for lost profits, loss of goodwill, work stoppage, accuracy of services, content or results, computer failure or malfunction, damages resulting from disabling of the services provided as part of the mobile store(s).

          </Typography>
        </Grid>
      </Grid>
    );
}