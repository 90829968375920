import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  Button,
  Box,
  Grid,
  InputLabel,
  TextField,
  IconButton,
  InputAdornment,
  useMediaQuery,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";

import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { ReactComponent as LogoIcon } from "../svgs/logo.svg";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { useAlert } from "../components/Alert";
import LoadingModal from "../components/LoadingModal";
import { useLocalStorage } from "../components/LocalStorageContext";

const LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      id
      token
    }
  }
`;

export default function LoginPage() {
  return (
    <div>
      <LoginForm />
    </div>
  );
}

function LoginForm() {
  const Alert = useAlert();
  const LocalStorage = useLocalStorage();
  const isPhone = useMediaQuery("(max-width:500px)");
  const useStyles = makeStyles((theme) => ({
    box: {
      minHeight: "100vh",
      backgroundColor: "#000000",
    },
    input: {
      height: "48px",
      padding: "0px",
      marginBottom: "5px",
      backgroundColor: "white",
    },
    labeltext: {
      color: "white",
      marginBottom: "10px",
      fontSize: "14px",
      fontWeight: 400,
    },
  }));
  const classes = useStyles();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const { handleSubmit, control } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
  });

  function _login(data) {
    login({ variables: { ...data } });
  }

  const [login, { loading }] = useMutation(LOGIN, {
    onCompleted({ login }) {
      if (login) {
        LocalStorage.setMemberToken(login.token);
        LocalStorage.forceUpdate();
        return Alert.notify("sign in suceesfully", () => history.replace("/"));
      } else {
        return Alert.notify("account or password incorrect!");
      }
    },
    onError(error) {
      return Alert.notify(error.message.replace("GraphQL error: ", ""));
    },
  });

  return (
    <Box>
      <LoadingModal loading={loading} />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.box}
      >
        {/* form */}
        <Grid
          container
          justifyContent="center"
          item
          xs={12}
          md={7}
          style={{ padding: "2em 0" }}
          spacing={2}
        >
          <Grid
            item
            xs={12}
            style={{
              display: isPhone ? "none" : "flex",
              justifyContent: "center",
              marginBottom: "100px",
            }}
          >
            <LogoIcon style={{ width: "300px", height: "64px" }} />
          </Grid>
          {/* 帳號 */}
          <Grid item xs={10} md={8}>
            <InputLabel className={classes.labeltext}>Account</InputLabel>
            <Controller
              name="username"
              control={control}
              rules={{
                required: "Account is required",
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  placeholder="please input your account"
                  {...field}
                  error={error}
                  helperText={error && error.message}
                  fullWidth
                  InputProps={{
                    className: classes.input,
                  }}
                />
              )}
            />
          </Grid>
          {/* 密碼 */}
          <Grid item xs={10} md={8}>
            <InputLabel className={classes.labeltext}>Password</InputLabel>
            <Controller
              name="password"
              control={control}
              rules={{
                required: "Password is required",
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  placeholder="Please input the password"
                  type={showPassword ? "text" : "password"}
                  {...field}
                  fullWidth
                  error={error}
                  helperText={error && error.message}
                  InputProps={{
                    className: classes.input,
                    endAdornment: (
                      <InputAdornment>
                        <IconButton
                          size="small"
                          onClick={() => setShowPassword((e) => !e)}
                        >
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          {/* 登入註冊 */}
          <Grid
            container
            spacing={2}
            justifyContent="flex-end"
            item
            xs={10}
            md={8}
          >
            <Grid
              item
              container
              justifyContent="flex-end"
              alignItems="center"
              xs={12}
            >
              <Button
                color="primary"
                size="small"
                onClick={() => history.push("/forgot-password")}
              >
                forget the password?
              </Button>
            </Grid>
            <Grid container item spacing={1} style={{ marginTop: "50px" }}>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{
                    height: "48px",
                    backgroundColor: "white",
                    color: "black",
                    fontSize: "16px",
                  }}
                  onClick={() => history.push("/register")}
                  fullWidth
                >
                  Register
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ height: "48px", fontSize: "16px" }}
                  fullWidth
                  onClick={handleSubmit(_login)}
                >
                  Login
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
